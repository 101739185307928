body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alert-info {
  margin-bottom:0;
}

.alignleft {
  float: left;
  width:33.33333%;
  text-align: left;
}
.aligncenter {
  float: "left";
  width: 66.666em;
  text-align: center;
  justify-content:center;
  align-items:center;
}

.alignright {
  float: "left";
  width: 33.33333%;
  text-align: right;
}
